<script>
import Card from "primevue/card";
import {ref} from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import {useStore} from "vuex";
import router from "@/router";

export default {
    name: "Initial",
    components: {
        Card,
        InputText,
        Button
    },
    setup() {
        const store = useStore();

        const testCount = ref(80);

        const startTest = () => {
            store.dispatch('setTestCount', testCount.value)
            router.push('test')
        }

        return {
            testCount,
            startTest
        }
    }
}
</script>

<template>
    <div id="box">
        <Card>
            <template #title>
                物理治療線上模擬測驗
            </template>
            <template #content>
                <h3>輸入考題數:</h3>
                <InputText v-model.number="testCount"/>
                <br>
                <Button label="開始測驗" class="p-button-raised p-button-info p-mt-2" @click="startTest"/>
            </template>
        </Card>
    </div>
</template>


<style scoped>
#box {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-card {
    width: 50rem;
}

.p-slider-horizontal, .p-inputtext {
    width: 14rem;
}

.p-slider-vertical {
    height: 14rem;
}
</style>