import {createStore} from 'vuex'

export default createStore({
    state: {
        testCount: 80
    },
    getters: {
        getTestCount(state) {
            return state.testCount;
        },
    },
    actions: {
        setTestCount({commit}, count) {
            commit('setTestCount', count);
        },
    },
    mutations: {
        setTestCount(state, count) {
            state.testCount = count
        },
    },
    modules: {}
})
