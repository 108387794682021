<script>
import {onMounted, ref, watch} from "vue";
import {apiGetTestQuestions} from "@/api";
import {useStore} from "vuex";
import Card from "primevue/card";
import RadioButton from "primevue/radiobutton";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import router from "@/router";

export default {
    name: "Test",
    components: {
        Card,
        RadioButton,
        Button,
        Dialog
    },
    setup() {
        const store = useStore();
        const items = ref([]);
        const showAns = ref(true);

        const rList = ref([]); // 答對列表
        const fList = ref([]); // 答錯列表

        const display = ref(false);

        onMounted(() => {
            apiGetTestQuestions().then((res) => {
                const data = res.data;
                const record = [];
                while (record.length < store.getters.getTestCount) {
                    const index = getRandom(0, data.length-1);
                    if (record.indexOf(index) < 0) {
                        record.push(index)
                        const target = data[index]
                        target['right'] = false;
                        target['show'] = false;
                        target['select'] = ref();
                        items.value.push(target)
                    }
                }
            })
        })

        const getRandom = (min, max) => {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        const calc = () => {
            rList.value = [];
            fList.value = [];
            for (const i in items.value) {
                const item = items.value[i]
                if (item.answer === item.select) {
                    item.right = true;
                    item.show = false;
                    rList.value.push(item);
                } else {
                    item.right = false;
                    item.show = true;
                    fList.value.push(item);
                }
            }
            display.value = true;
        }

        const closeDialog = () => {
            display.value = false;
        }

        const backHome = () => {
            router.push('/')
        }

        return {
            items,
            showAns,
            calc,
            display,
            closeDialog,
            rList,
            fList,
            backHome
        }
    }
}
</script>

<template>
    <div class="p-grid p-m-2">
        <div class="p-col-12" v-for="item in items">
            <Card>
                <template #title>
                    {{ item.question }}
                </template>
                <template #content>
                    <div v-for="(option, index) of item.options" :key="option" class="p-field-radiobutton">
                        <RadioButton :id="option" name="options" :value="option" v-model="item.select"/>
                        <label :for="option">{{ option }}</label>
                    </div>
                </template>
                <template #footer>
                    <template v-if="item.show">
                        <h2>
                            {{ item.answer }}
                        </h2>
                    </template>
                </template>
            </Card>
        </div>
    </div>
    <div class="p-d-flex p-jc-center p-mt-2" style="width:100%">
        <Button label="作答結束 進行計分" class="p-button-raised p-button-success p-mr-2" @click="calc"/>
        <Button label="重新出題" class="p-button-raised p-button-warning" @click="backHome"/>
    </div>

    <Dialog v-model:visible="display">
        <template #header>
            <h3>作答成績</h3>
        </template>

        總共做題：{{ items.length }} 題<br>
        答對：{{ rList.length }} 題<br>
        答錯：{{ fList.length }} 題

        <template #footer>
            <Button label="關閉" icon="pi pi-check" autofocus @click="closeDialog"/>
        </template>
    </Dialog>

</template>

<style scoped>
::v-deep(.p-card-title) {
    text-align: left;
}

::v-deep(.p-card-content) {
    text-align: left;
}

::v-deep(.p-card-footer) {
    background-color: #F6A0DA;
    padding: 0 !important;
}
</style>
