import {createRouter, createWebHistory} from 'vue-router'
import Test from '../views/Test.vue'
import Initial from "@/views/Initial";

const routes = [
    {
        path: '/',
        name: 'Initial',
        component: Initial
    },
    {
        path: '/test',
        name: 'Test',
        component: Test
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
